import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, data, vm, addressInfo, LanguageInfo, AccadamicInfo, trainingDetails, seminarDetails, qulificationDetails, annexure1Details, annexure2Details, annexure3Details) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
                // columns: reportHeadData.reportHeadColumn
            },
            {
                columns: [
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: '' }
                ],
                style: 'krishi'
            },
            { text: vm.$t('admission_form.application_details'), style: 'hh', alignment: 'center' }
        ]

        // if (reportHeadData.projectName) {
            // pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        // }

        pdfContent.push({ text: vm.$t('admission_form.gen_inf'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('admission_form.circular_memo'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.cir_name : data.cir_name_bn, style: 'td' },
                        { text: vm.$t('admission_form.candidate_type'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.canType_name : data.canType_name_bn, style: 'td' },
                        { text: vm.$t('admission_form.candidate_org'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.canOrg_name : data.canOrg_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('admission_form.field_study'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.field_of_study : data.field_of_study_bn, style: 'td' },
                        { text: vm.$t('educational_management.name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.name : data.name_bn, style: 'td' },
                        { text: vm.$t('externalUserIrrigation.father_name'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.father_name : data.father_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('externalUserIrrigation.mother_name'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.mother_name : data.mother_name_bn, style: 'td' },
                        { text: vm.$t('externalUserIrrigation.date_of_birth'), style: 'th' },
                        { text: dateFormat(data.date_of_birth), style: 'td' },
                        { text: vm.$t('admission_form.age'), style: 'th' },
                        { text: data.age, style: 'td' }
                    ],
                    [
                        { text: vm.$t('admission_form.place_of_birth'), style: 'th' },
                        { text: data.place_of_birth, style: 'td' },
                        { text: vm.$t('admission_form.mat_status'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.mStatus_name : data.mStatus_name_bn, style: 'td' },
                        { text: vm.$t('admission_form.nid_no'), style: 'th' },
                        { text: data.nid, style: 'td' }
                    ],
                    [
                        { text: vm.$t('admission_form.mobile_no'), style: 'th' },
                        { text: data.mobile_no, style: 'td' },
                        { text: vm.$t('admission_form.email'), style: 'th' },
                        { text: data.email, style: 'td' },
                        { text: vm.$t('educational_management.designation'), style: 'th' },
                        { text: (i18n.locale === 'en') ? data.designation : data.designation_bn, style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.add_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: vm.$t('educational_management.present_business_address'), style: 'header11', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('org_pro.area_type'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.area_name : addressInfo.area_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_division.division'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.div_name : addressInfo.div_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_district.district'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.dis_name : addressInfo.dis_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.upa_name : addressInfo.upa_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_union.union'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.union_name : addressInfo.union_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.city_corporation'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.cityCor_name : addressInfo.cityCor_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro.pauroshoba'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.pauro_name : addressInfo.pauro_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.ward'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.ward_name : addressInfo.ward_name_bn, style: 'td' },
                        { text: vm.$t('globalTrans.village'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.village_name : addressInfo.village_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('admission_form.house_number'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.house_number_bn : addressInfo.house_number_bn, style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('educational_management.permanent_address'), style: 'header11', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('org_pro.area_type'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_area_name : addressInfo.per_area_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_division.division'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_div_name : addressInfo.per_div_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_district.district'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_dis_name : addressInfo.per_dis_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_upa_name : addressInfo.per_upa_name_bn, style: 'td' },
                        { text: vm.$t('org_pro_union.union'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_union_name : addressInfo.per_union_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.city_corporation'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_cityCor_name : addressInfo.per_cityCor_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('org_pro.pauroshoba'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_pauro_name : addressInfo.per_pauro_name_bn, style: 'td' },
                        { text: vm.$t('org_pro.ward'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_ward_name : addressInfo.per_ward_name_bn, style: 'td' },
                        { text: vm.$t('globalTrans.village'), style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? addressInfo.per_village_name : addressInfo.per_village_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('admission_form.house_number'), style: 'th' },
                        { text: (i18n.locale === 'en') ? addressInfo.per_house_number_bn : addressInfo.per_house_number_bn, style: 'td' },
                        {},
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.lang_skill_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('admission_form.english_reading_skill'), style: 'th' },
                        { text: (typeof vm.languageInfo !== 'undefined') ? vm.$i18n.locale === 'en' ? LanguageInfo.eReading_name : LanguageInfo.eReading_name_bn : '', style: 'td' },
                        { text: vm.$t('admission_form.english_writing_skill'), style: 'th' },
                        { text: (typeof vm.languageInfo !== 'undefined') ? (i18n.locale === 'en') ? LanguageInfo.eWriting_name : LanguageInfo.eWriting_name_bn : '', style: 'td' },
                        { text: vm.$t('admission_form.english_speaking_skill'), style: 'th' },
                        { text: (typeof vm.languageInfo !== 'undefined') ? (i18n.locale === 'en') ? LanguageInfo.eSpeaking_name : LanguageInfo.eSpeaking_name_bn : '', style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.aca_record_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        var allRow = []
        var tabledata = [
            // { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('admission_form.education_level'), style: 'th' },
            { text: vm.$t('admission_form.title_degree'), style: 'th' },
            { text: vm.$t('admission_form.group'), style: 'th' },
            { text: vm.$t('admission_form.name_inst'), style: 'th' },
            { text: vm.$t('admission_form.inst_address'), style: 'th' },
            { text: vm.$t('admission_form.year_attend_from'), style: 'th' },
            { text: vm.$t('admission_form.year_attend_to'), style: 'th' },
            { text: vm.$t('admission_form.score_sys'), style: 'th' },
            { text: vm.$t('admission_form.class'), style: 'th' },
            { text: vm.$t('admission_form.pass_year'), style: 'th' }
        ]
        allRow.push(tabledata)
        // var serial = 0
        AccadamicInfo.map(item => {
            // serial += 1
            tabledata = [
                // { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.education_name_bn : item.education_name, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.degree_title_bn : item.degree_title, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.group_bn : item.group, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.institute_bn : item.institute, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.address_bn : item.address, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.year_from : item.year_from, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.year_to : item.year_to, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.score_name_bn : item.score_name, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.class_or_grade_id : item.class_or_grade_id, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.passing_year : item.passing_year, style: 'td' }
            ]
            allRow.push(tabledata)
        })
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                body: allRow
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.training_details'), style: 'header2', alignment: 'center', decoration: 'underline' })
        var allRowT = []
        var tabledataT = [
            // { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('admission_form.training_type'), style: 'th' },
            { text: vm.$t('admission_form.training_title'), style: 'th' },
            { text: vm.$t('admission_form.batch'), style: 'th' },
            { text: vm.$t('admission_form.venue'), style: 'th' },
            { text: vm.$t('globalTrans.from_date'), style: 'th' },
            { text: vm.$t('globalTrans.to_date'), style: 'th' }
        ]
        allRowT.push(tabledataT)
        // var serial = 0
        trainingDetails.map(item => {
            // serial += 1
            tabledataT = [
                // { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.tType_name_bn : item.tType_name, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.tTitle_name_bn : item.tTitle_name, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.batch : item.batch, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.venue_bn : item.venue, style: 'td' },
                { text: dateFormat(item.from_date), style: 'td' },
                { text: dateFormat(item.to_date), style: 'td' }
            ]
            allRowT.push(tabledataT)
        })
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['*', '*', '*', '*', '*', '*'],
                body: allRowT
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.seminar'), style: 'header2', alignment: 'center', decoration: 'underline' })
        var allRowS = []
        var tabledataS = [
            // { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('admission_form.semi_name'), style: 'th' },
            { text: vm.$t('admission_form.semi_v'), style: 'th' },
            { text: vm.$t('globalTrans.from_date'), style: 'th' },
            { text: vm.$t('globalTrans.to_date'), style: 'th' }
        ]
        allRowS.push(tabledataS)
        // var serial = 0
        seminarDetails.map(item => {
            // serial += 1
            tabledataS = [
                // { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.seminar_name_bn : item.seminar_name, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.venue_bn : item.venue, style: 'td' },
                { text: dateFormat(item.from_date), style: 'td' },
                { text: dateFormat(item.to_date), style: 'td' }
            ]
            allRowS.push(tabledataS)
        })
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['*', '*', '*', '*'],
                body: allRowS
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.qualification'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*'],
                body: [
                    [
                        { text: vm.$t('admission_form.other_qua'), style: 'th' },
                        { text: vm.qulificationDetails !== null ? vm.$i18n.locale === 'en' ? qulificationDetails.qualification : qulificationDetails.qualification_bn : '', style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.annexure_1'), style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('admission_form.evidence_of_contribution'), style: 'th' },
                        { text: vm.annexure1Details !== null ? vm.$i18n.locale === 'en' ? annexure1Details.evidence : annexure1Details.evidence_bn : '', style: 'td' },
                        { text: vm.$t('admission_form.membership_of_society'), style: 'th' },
                        { text: vm.annexure1Details !== null ? (i18n.locale === 'en') ? annexure1Details.membership : annexure1Details.membership_bn : '', style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.annexure_2'), style: 'header2', alignment: 'center', decoration: 'underline' })
        var allRowTo = []
        var tabledataTo = [
            // { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('admission_form.organization'), style: 'th' },
            { text: vm.$t('admission_form.position_name'), style: 'th' },
            { text: vm.$t('admission_form.place_of_posting'), style: 'th' },
            { text: vm.$t('admission_form.employement_start_date'), style: 'th' },
            { text: vm.$t('admission_form.employement_end_date'), style: 'th' },
            { text: vm.$t('admission_form.remark'), style: 'th' }
        ]
        allRowTo.push(tabledataTo)
        // var serial = 0
        annexure2Details.map(item => {
            // serial += 1
            tabledataTo = [
                // { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? vm.getOrganization(item.org_id) : item.other_organization, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.position_bn : item.position, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.posting_bn : item.posting, style: 'td' },
                { text: dateFormat(item.start_date), style: 'td' },
                { text: dateFormat(item.end_date), style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.remark_bn : item.remark, style: 'td' }
            ]
            allRowTo.push(tabledataTo)
        })
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['*', '*', '*', '*', '*', '*'],
                body: allRowTo
            }
        })
        pdfContent.push({ text: vm.$t('admission_form.annexure_3_info'), style: 'header2', alignment: 'center', decoration: 'underline' })
        var allRowThree = []
        var tabledataThree = [
            // { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('admission_form.e_year'), style: 'th' },
            { text: vm.$t('admission_form.e_performance'), style: 'th' }
        ]
        allRowThree.push(tabledataThree)
        // var serial = 0
        annexure3Details.map(item => {
            // serial += 1
            tabledataThree = [
                // { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.year_name_bn : item.year_name, style: 'td' },
                { text: vm.$i18n.locale === 'bn' ? item.type_name_bn : item.type_name, style: 'td' }
            ]
            allRowThree.push(tabledataThree)
        })
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['*', '*'],
                body: allRowThree
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'landscape',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 4]
                },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                hh: {
                    fontSize: 17,
                    bold: true,
                    margin: [25, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('program-management')
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
