<template>
    <b-container fluid>
      <!-- <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('admission_form.application') }}</h4>
          </template>
            <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      :label="$t('social_service.case_history_no')"
                      label-for="gender"
                      >
                      <b-form-input
                      v-model="search.case_history_no"
                      ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('social_service.ofc_name')"
                      label-for="division_id"
                      >
                      <b-form-input
                      v-model="search.case_history_no"
                      ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('social_service.patient')"
                        label-for="district_id"
                        >
                        <b-form-input
                          v-model="search.case_history_no"
                          ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
              <b-button type="button" variant="primary" @click="searchData">
              {{ $t('globalTrans.search')}}
            </b-button>
          </template>
      </iq-card> -->
      <b-row>
        <b-col md="12">
          <b-overlay :show="loading">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('admission_form.application') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" v-b-modal.modal-4 @click="addForm" v-if="pendingApp === 0">
                  {{ $t('globalTrans.add_new') }}
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="12" class="table-responsive">
                      <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns">
                        <template v-slot:cell(index)="data">
                          {{ $n(data.index + pagination.slOffset) }}
                        </template>
                        <template v-slot:cell(application_id)="data">
                          {{ $n(data.item.application_id, { useGrouping: false }) }}
                        </template>
                        <template v-slot:cell(can_org_id)="data">
                          {{ getCandidateOrganization(data.item.can_org_id, data.item.can_org_type)}}
                        </template>
                        <template v-slot:cell(view)="data">
                          <b-button v-b-modal.modal-6 variant=" iq-bg-success mr-1" size="sm" @click="viewDetails(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                        </template>
                        <template v-slot:cell(action)="data">
                          <slot v-if="data.item.status === 0">
                            <router-link :to="'/grant-panel/application-form-main?id='+ data.item.id" variant=" iq-bg-success" :class="'btn btn-success btn-sm'"><i class="ri-ball-pen-fill m-0"></i></router-link>
                            <b-button variant="warning" class="ml-1" size="sm" title="Final Save cannot be reverted!" @click="finalSave(data.item)"><i class="ri-lock-fill"></i></b-button>
                          </slot>
                          <slot v-else>
                            <b-button variant="success mr-1" size="sm">{{ getStatus(data.item.status) }}</b-button>
                          </slot>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                      />
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-modal id="modal-6" size="xl" :title="$t('globalTrans.details')"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
        <Details :id="id" :key="id" ref="details"/>
      </b-modal>
    </b-container>
</template>
<script>
import Details from './applicationDetails'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { grantApplicationList, appFinalSave } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import StaticData from './static_data.js'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      loading: false,
      pendingApp: 0,
      search: {
        gender: '',
        month: '',
        division_id: '',
        patient_id: 0
      },
      id: '',
      item: '',
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    genderList: function () {
      return StaticData.genderList(this)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('externalUserIrrigation.application_id'), class: 'text-left' },
        { label: this.$t('admission_form.application_name'), class: 'text-left' },
        { label: this.$t('globalTrans.organization'), class: 'text-left' },
        { label: this.$t('globalTrans.details'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'can_org_id' },
          { key: 'view' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'can_org_id' },
          { key: 'view' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    viewDetails (item) {
      this.id = item.id
    },
    editItem (item) {
      this.$router.push({ path: `/grant-panel/application-form-main/${item.id}` })
    },
    addForm () {
      this.$router.push({ path: '/grant-panel/application-form-main' })
    },
    async loadData () {
      this.loading = true
      const applicantId = this.$store.state.Auth.authUser.id
      const params = Object.assign({}, this.search, { applicant_id: applicantId, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const response = await RestApi.getData(incentiveGrantServiceBaseUrl, grantApplicationList, params)
      this.loading = false
      if (response.success) {
        this.pendingApp = response.pending
        this.$store.dispatch('setList', this.getRelationalData(response.data.data))
        this.paginationData(response.data)
      }
      /* if (response.data.total === 0) {
        this.addForm()
      } */
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const evaluationData = {
          seizer_name: item.seizer_name_bn !== undefined ? item.seizer_name : '',
          seizer_name_bn: item.seizer_name !== undefined ? item.seizer_name_bn : ''
        }
        return Object.assign({}, item, evaluationData)
      })
      return listData
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getCandidateOrganization (orgId, orgType) {
      if (orgType === 2) {
        const org = this.$store.state.ExternalUserIrrigation.incentiveGrant.narseInstituteList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      } else {
        const org = this.$store.state.ExternalUserIrrigation.incentiveGrant.orgList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('globalTrans.finalSave')
      } else if (status === 2) {
        return this.$t('globalTrans.approve')
      } else if (status === 3) {
        return this.$t('globalTrans.reject')
      }
    },
    pdfExport () {
        this.$refs.details.pdfExport()
    },
    finalSave (item) {
      this.$swal({
        title: this.$t('admission_form.final_save_msg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.appFinalSave(item)
        }
      })
    },
    async appFinalSave (item) {
      this.loading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${appFinalSave}/${item.id}`)
      this.loading = false
      if (result.success) {
        // push notification
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)

        this.$store.dispatch('ExternalUserIrrigation/setGrantApplicantDetails', {})
        this.loadData()

        this.$toast.success({
          title: 'Success',
          message: 'Final Save Successfully',
          color: '#D6E09B'
        })
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
