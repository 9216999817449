const genderList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.boy'),
          gender: vm.$t('pusti_mapping.boy'),
          value: 1,
          text: vm.$t('pusti_mapping.boy')
        },
        {
          label: vm.$t('pusti_mapping.girl'),
          gender: vm.$t('pusti_mapping.girl'),
          value: 2,
          text: vm.$t('pusti_mapping.girl')
        }
    ]
}
const fatherEdutcationStatusList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.never_goes_school'),
          education: vm.$t('pusti_mapping.never_goes_school'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.primary'),
          education: vm.$t('pusti_mapping.primary'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.secondary'),
          education: vm.$t('pusti_mapping.secondary'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.honours'),
          education: vm.$t('pusti_mapping.honours'),
          value: 4
        }
    ]
}
const motherEducationStatusList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.never_goes_school'),
          education: vm.$t('pusti_mapping.never_goes_school'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.primary'),
          education: vm.$t('pusti_mapping.primary'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.secondary'),
          education: vm.$t('pusti_mapping.secondary'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.honours'),
          education: vm.$t('pusti_mapping.honours'),
          value: 4
        }
    ]
}
const earningMemberList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.father'),
          member: vm.$t('pusti_mapping.father'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.mother'),
          member: vm.$t('pusti_mapping.mother'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.father_mother'),
          member: vm.$t('pusti_mapping.father_mother'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.others'),
          member: vm.$t('pusti_mapping.others'),
          value: 4
        }
    ]
}
const monthlyIncomeList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.<5000'),
          m_income: vm.$t('pusti_mapping.<5000'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.5000-10000'),
          m_income: vm.$t('pusti_mapping.5000-10000'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.10000-20000'),
          m_income: vm.$t('pusti_mapping.10000-20000'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.>20000'),
          m_income: vm.$t('pusti_mapping.>20000'),
          value: 4
        }
    ]
}
const incomeSourceList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.business_harvest'),
          source: vm.$t('pusti_mapping.business_harvest'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.service'),
          source: vm.$t('pusti_mapping.service'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.teacher'),
          source: vm.$t('pusti_mapping.teacher'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.others'),
          source: vm.$t('pusti_mapping.others'),
          value: 4
        }
    ]
}
const familyCategoryList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.single'),
          category: vm.$t('pusti_mapping.single'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.join'),
          category: vm.$t('pusti_mapping.join'),
          value: 2
        }
    ]
}
const religionList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.muslim'),
          religion: vm.$t('pusti_mapping.muslim'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.hindu'),
          religion: vm.$t('pusti_mapping.hindu'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.khrischan'),
          religion: vm.$t('pusti_mapping.khrischan'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.buddhist'),
          religion: vm.$t('pusti_mapping.buddhist'),
          value: 4
        }
    ]
}
const muacList = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.sam'),
          gender: vm.$t('pusti_mapping.sam'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.mam'),
          gender: vm.$t('pusti_mapping.mam'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.normal'),
          gender: vm.$t('pusti_mapping.normal'),
          value: 3
        }
    ]
}
const q1List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.12_15_y'),
          option: vm.$t('pusti_mapping.12_15_y'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.16_19_y'),
          option: vm.$t('pusti_mapping.16_19_y'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.20_23_y'),
          option: vm.$t('pusti_mapping.20_23_y'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.24_27_y'),
          option: vm.$t('pusti_mapping.24_27_y'),
          value: 4
        },
        {
          label: vm.$t('pusti_mapping.28_31_y'),
          option: vm.$t('pusti_mapping.28_31_y'),
          value: 5
        },
        {
          label: vm.$t('pusti_mapping.32-35_y'),
          option: vm.$t('pusti_mapping.32-35_y'),
          value: 6
        },
        {
          label: vm.$t('pusti_mapping.greater_36_y'),
          option: vm.$t('pusti_mapping.greater_36_y'),
          value: 7
        }
    ]
}
const q2List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.1'),
          option: vm.$t('pusti_mapping.1'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.2'),
          option: vm.$t('pusti_mapping.2'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.3'),
          option: vm.$t('pusti_mapping.3'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.4'),
          option: vm.$t('pusti_mapping.4'),
          value: 4
        },
        {
          label: vm.$t('pusti_mapping.5'),
          option: vm.$t('pusti_mapping.5'),
          value: 5
        },
        {
          label: vm.$t('pusti_mapping.6'),
          option: vm.$t('pusti_mapping.6'),
          value: 6
        }
    ]
}
const q4List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.less_2_y'),
          option: vm.$t('pusti_mapping.less_2_y'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.2_3_y'),
          option: vm.$t('pusti_mapping.2_3_y'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.3_4_y'),
          option: vm.$t('pusti_mapping.3_4_y'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.greater_4_y'),
          option: vm.$t('pusti_mapping.greater_4_y'),
          value: 4
        }
    ]
}
const q5List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.less_2_5_kg'),
          option: vm.$t('pusti_mapping.less_2_5_kg'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.greater_2_5_kg'),
          option: vm.$t('pusti_mapping.greater_2_5kg'),
          value: 2
        }
    ]
}
const q7List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.superstition'),
          option: vm.$t('pusti_mapping.superstition'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.illness'),
          option: vm.$t('pusti_mapping.illness'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.others'),
          option: vm.$t('pusti_mapping.others'),
          value: 3
        }
    ]
}
const q11List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.1_items'),
          option: vm.$t('pusti_mapping.1_items'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.2_items'),
          option: vm.$t('pusti_mapping.2_items'),
          value: 2
        },
        {
          label: vm.$t('pusti_mapping.3_items'),
          option: vm.$t('pusti_mapping.3_items'),
          value: 3
        },
        {
          label: vm.$t('pusti_mapping.4_items'),
          option: vm.$t('pusti_mapping.4_items'),
          value: 4
        },
        {
          label: vm.$t('pusti_mapping.5_items'),
          option: vm.$t('pusti_mapping.5_items'),
          value: 5
        },
        {
          label: vm.$t('pusti_mapping.6_items'),
          option: vm.$t('pusti_mapping.6_items'),
          value: 6
        }
    ]
}
const q18List = (vm) => {
    return [
        {
          label: vm.$t('pusti_mapping.yes'),
          option: vm.$t('pusti_mapping.yes'),
          value: 1
        },
        {
          label: vm.$t('pusti_mapping.no'),
          option: vm.$t('pusti_mapping.no'),
          value: 2
        }
    ]
}
export default {
  genderList,
  fatherEdutcationStatusList,
  motherEducationStatusList,
  earningMemberList,
  monthlyIncomeList,
  incomeSourceList,
  familyCategoryList,
  religionList,
  muacList,
  q1List,
  q2List,
  q4List,
  q5List,
  q7List,
  q11List,
  q18List
}
